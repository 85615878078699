


















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import PageView from '@/components/templates/h/yosan-haibun/weight-adj-conf/PageView.vue'
import {
  WeightAdjConfExtractCondition,
  WeightAdjConfOutputCondition,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPWeightAdjConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
// import { TemplateFile, ResourceType } from '@/types/generated/graphql'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [OUTPUT_COND_STORE.all],
  itemOutput: [OUTPUT_COND_ITEM.budgetGroup],
  delOutput: [],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPWeightAdjConf,
      templateFile: TemplateFile.UiPWeightAdjConf_01,
      extractCondition: {
        itemStore: {
          chubunrui: [],
        },
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput,
        },
        {
          outputCondType: 'Item',
          displayItems: DEFAULT_OUTPUT_CONST.itemOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.itemOutput,
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: WeightAdjConfExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: WeightAdjConfOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.totalCount = null
      this.exportSpinner = true
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPWeightAdjConfsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPWeightAdjConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({
        files: files,
        resourceType: this.resourceType,
        optionXlsx: { skipRows: 3 },
      })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      if (this.extractCondition.itemStore.chubunrui.length == 0) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.itemStore.chubunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.chubunrui'),
            selected: this.extractCondition.itemStore.chubunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPWeightAdjConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.weight_adj_conf,
        })
      }
    },

    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPWeightAdjConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        budgetGroupId
        discardRateMinLimit
        discardRateMinParam
        discardRateMaxLimit
        discardRateMaxParam
        discardSumMinLimit
        discardSumMinParam
        discardSumMaxLimit
        discardSumMaxParam
        consumptionMinLimit
        consumptionMinParam
        consumptionMaxLimit
        consumptionMaxParam
        skuRatioMinLimit
        skuRatioMinParam
        skuRatioMaxLimit
        skuRatioMaxParam
        dspzeroParam
        skuzeroMinParam
        skuzeroMaxParam
        weeklySwapDisposalRate
        dailySwapDisposalRate
        isStockCalc
        targetStock
        configuredFlg
        lastModifiedDatetime
        lastModifiedUserName
      }
    }
  }
}
`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      // TODO: category0カラムが追加され次第抽出条件のbudgetGroupIdをcategory0に変更する
      const TRAN_REF_ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['vUiPBudgetGroup/category0'],
          op: WhereOp.In,
          value: [''],
        },
        { field: ['configuredFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(TRAN_REF_ITEM_SEARCH_CONDITION))
      setWhereValue(
        where,
        'vUiPBudgetGroup/category0',
        this.extractCondition.itemStore.chubunrui
      )
      setWhereValue(where, 'configuredFlg', this.outputCondition.extractTarget)
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'budgetGroupId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'lastModifiedDatetime',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
  },
})
