















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  dateRange,
  WeightAdjConfExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
  },
  props: {},
  setup(_, context) {
    const itemStoreUseList = ref<itemHierarchyUseList>([['Chubunrui']])
    const validData = ref<dateRange>(['', ''])
    // ○商品・店舗での絞り込み
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )

    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: WeightAdjConfExtractCondition = {
        itemStore: {
          chubunrui: extractConditionOfItemStore.value.chubunrui || [],
          chubunruiCount: extractConditionOfItemStore.value.chubunruiCount || 0,
        },
      }
      console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/

    watch(validData, (newVal) => {
      // console.log('change-validData', { newVal })
      changeExtractCondition()
    })
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = ['中分類：常に入力']
    return {
      requiredItemInput,
      itemStoreUseList,
      changeExtractConditionOfItemStore,
    }
  },
})
